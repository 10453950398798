import * as THREE from 'three';

document.addEventListener('DOMContentLoaded', function() {

    const themeToggle = document.querySelector('.theme-toggle');
    themeToggle.addEventListener('click', function(e) {
        e.preventDefault();
        document.body.classList.toggle('dark-theme');
        themeToggle.classList.toggle('dark');
        localStorage.setItem('darkMode', document.body.classList.contains('dark-theme'));
    });

    // save the theme locally and load it on page load
    const colorThemePickerButton = document.querySelectorAll('.theme-picker-button');
    colorThemePickerButton.forEach(button => {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            let colorTheme = button.dataset.theme;
            colorThemePickerButton.forEach(button => {
                button.classList.remove('active');
            });
            button.classList.toggle('active');
            document.body.classList.remove('theme-1');
            document.body.classList.remove('theme-2');
            document.body.classList.remove('theme-3');
            document.body.classList.add(`theme-${colorTheme}`);
            localStorage.setItem('theme', `theme-${colorTheme}`);
        });
    });

    const colortheme = localStorage.getItem('theme');
    if (colortheme) {
        document.body.classList.remove('theme-1');
        document.body.classList.remove('theme-2');
        document.body.classList.remove('theme-3');
        document.body.classList.add(colortheme);
        colorThemePickerButton.forEach(button => {
            button.classList.remove('active');
        });
        document.querySelector(`.theme-picker-button[data-theme="${colortheme.split('-')[1]}"]`).classList.add('active');
    }
    
    const theme = localStorage.getItem('darkMode');
    if (theme === 'true') {
        document.body.classList.toggle('dark-theme');
        themeToggle.classList.toggle('dark');
    }

});